import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import trackEvent from '../components/analytics';
import Layout, { Player } from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_60.mp3';
import Background from '../images/landscape-1.jpg';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  text-align: center;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  > * {
    animation: bounceIn;
    animation-duration: 1s;
  }
`;

const ViewButtons = styled.div`
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 1rem;
  animation: bounceIn;
  animation-duration: 1s;
`;

const End = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO pathname="/fr/end" />
    <ViewArticle>
      <h1>MERCI !</h1>
      <p>
        <strong>Partage la formation FinDiETou avec tes amis</strong>
      </p>
      <ViewButtons>
        <FacebookShareButton
          url={'https://findietou.com/'}
          title={
            "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
          }
          hashtag="#FinDiETou"
          quote={
            'L’application FinDiETou te donne des astuces et conseils pratiques pour développer ton activité, augmenter tes revenus, et épargner ton argent pour tes projets professionnels et personnels. Bonne formation !'
          }
          onClick={() => {
            trackEvent('Share Buttons', 'User clicked the Facebook Button', 'yes');
          }}
        >
          <FacebookIcon size={32} />
        </FacebookShareButton>
        <TwitterShareButton
          url={'https://findietou.com/'}
          title={
            "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
          }
          hashtag="#FinDiETou"
          onClick={() => {
            trackEvent('Share Buttons', 'User clicked the Twitter Button', 'yes');
          }}
        >
          <TwitterIcon size={32} />
        </TwitterShareButton>
        <WhatsappShareButton
          url={'https://findietou.com/'}
          title={
            "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
          }
          separator="-"
          onClick={() => {
            trackEvent('Share Buttons', 'User clicked the Whatsapp Button', 'yes');
          }}
        >
          <WhatsappIcon size={32} />
        </WhatsappShareButton>
      </ViewButtons>
      <p>
        <Link to="/">
          <Button color="warning">
            <i className="fa fa-home" /> Accueil
          </Button>
        </Link>
      </p>
    </ViewArticle>
    <Player sound={Sound} />
  </Layout>
);

export default End;
